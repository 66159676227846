<template>
    <div>
        <!-- 一维数组 -->
        <div v-if="list && list.length">
            <vue-perfect-scrollbar style="height: calc(100vh - 19rem)">
                <b-list-group flush class="menu-link">
                    <b-list-group-item
                        v-for="(item, index) in list"
                        :key="index"
                        class="py-1 px-2"
                        @click="$emit('select', item)"
                    >
                        <feather-icon
                            v-if="item.icon"
                            :icon="item.icon"
                            class="mr-50"
                        />
                        <span> {{ item.title }}</span>
                    </b-list-group-item>
                </b-list-group>
            </vue-perfect-scrollbar>
        </div>

        <div v-else-if="tree && tree.length">
            <tree
                :nodes="tree"
                :setting="tree_settings"
                @onClick="
                    (evt, treeId, treeNode) => {
                        $emit('select', treeNode);
                    }
                "
            />
        </div>

        <!-- 二维数组：楼层和系统 -->
        <b-tabs v-else fill class="tabsMenu">
            <b-tab v-if="floors && floors.length" title="楼层">
                <vue-perfect-scrollbar style="height: calc(100vh - 22rem)">
                    <b-list-group flush class="menu-link">
                        <b-list-group-item
                            v-for="(floor, floorIndex) in floors"
                            :key="floorIndex"
                            @click="$emit('select', floor)"
                        >
                            {{ floor.title }}
                        </b-list-group-item>
                    </b-list-group>
                </vue-perfect-scrollbar>
            </b-tab>

            <b-tab v-if="system" title="系统">
                <slot name="left-menu-system"></slot>

                <vue-perfect-scrollbar style="height: calc(100vh - 22rem)">
                    <app-collapse
                        v-if="
                            system_tree[0] &&
                            system_tree[0].children &&
                            system_tree[0].children.length > 0
                        "
                        accordion
                        class="collapse-menu"
                    >
                        <app-collapse-item
                            v-for="(lvl_1, lvl_1_index) in system_tree"
                            :key="'lvl_1_' + lvl_1_index"
                            :title="lvl_1.title"
                        >
                            <template
                                v-if="
                                    lvl_1.children[0].children &&
                                    lvl_1.children[0].children.length > 0
                                "
                            >
                                <app-collapse
                                    accordion
                                    class="collapse-menu-lvl2"
                                >
                                    <app-collapse-item
                                        v-for="(
                                            lvl_2, lvl_2_index
                                        ) in lvl_1.children"
                                        :key="'lvl_2_' + lvl_2_index"
                                        :title="lvl_2.title"
                                    >
                                        <list-group
                                            :list="lvl_2.children"
                                            @select="
                                                (event) => {
                                                    $emit('select', event);
                                                }
                                            "
                                        ></list-group>
                                    </app-collapse-item>
                                </app-collapse>
                            </template>
                            <template v-else>
                                <list-group
                                    :list="lvl_1.children"
                                    @select="
                                        (event) => {
                                            $emit('select', event);
                                        }
                                    "
                                ></list-group>
                            </template>
                        </app-collapse-item>
                    </app-collapse>

                    <list-group
                        v-else
                        :list="system_tree"
                        @select="
                            (event) => {
                                $emit('select', event);
                            }
                        "
                    ></list-group>
                </vue-perfect-scrollbar>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import tree from "vue-giant-tree";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ListGroup from "@/views/front/jsf35/subsystems/layout/components/LeftMenuListGroup";
import store from "@/store";
import { reactive, toRefs, watch } from "@vue/composition-api";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
    name: "LeftMenu",
    components: {
        tree,
        AppCollapse,
        AppCollapseItem,
        ListGroup,
        VuePerfectScrollbar,
    },
    props: ["list", "system", "floors", "tree","systemTree"],
    setup(props, { emit }) {
        const event = reactive({
            system_tree:
                props.system === true
                    ? props.systemTree
                    : props.system,
        });

        const tree_settings = {
            data: {
                simpleData: {
                    enable: true,
                    idKey: "id",
                    pIdKey: "pid",
                },
            },
        };
        //若store中的数据更新，则更新左侧菜单
        watch(
            () => store.state.jsf35.equip_tree,
            (equip_tree) => {
                if (equip_tree && props.system === true) {
                    event.system_tree = equip_tree;
                }
            }
        );

        const list_item_selected = (list_item) => {
            if (list_item.equip_id) {
                store.commit("jsf35/setEquipSelected", list_item);
            } else {
                emit("select", list_item);
            }
        };

        return {
            ...toRefs(event),
            list_item_selected,
            tree_settings,
        };
    },
};
</script>

<style lang="scss" scoped>
/* ::-webkit-scrollbar {
    display: none !important;
} */

.menu-link {
    .list-group-item {
        padding: 1rem 2rem;
        cursor: pointer;
    }
}

.collapse-menu {
    ::v-deep .card {
        margin-top: 0 !important;

        .card-body {
            margin: 0 !important;
            padding: 0 !important;

            .collapse-menu-link {
                .list-group-item {
                    cursor: pointer;
                }
            }

            .collapse-menu-lvl2 {
                .card {
                    margin: 0 !important;
                    padding: 0 !important;

                    .card-header {
                        padding-left: 2rem;
                    }

                    .card-body {
                        margin: 0 !important;
                        padding: 0 !important;

                        .collapse-menu-link {
                            .list-group-item {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}

.tabsMenu {
    margin-top: 1rem;

    ::v-deep .nav-tabs {
        margin-bottom: 0 !important;
    }
}

.jsf-list-group {
    background-color: #81725f !important;
}
</style>
