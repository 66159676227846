var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center mb-2",staticStyle:{"z-index":"2 !important"},attrs:{"cols":"2"}},[_c('page-title',{staticStyle:{"min-width":"18rem"}})],1),_c('b-col',{staticStyle:{"z-index":"2 !important"},attrs:{"cols":"10"}},[_vm._t("top")],2)],1),_c('b-row',[_c('b-col',{staticClass:"position-relative",staticStyle:{"z-index":"2 !important"},attrs:{"cols":"2"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-button-toolbar',{staticClass:"subsystemButtonGroup"},[_c('b-button-group',{staticStyle:{"flex":"1","display":"flex"}},_vm._l((_vm.topBarItems),function(btn,btnIndex){return _c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],key:btnIndex,attrs:{"variant":_vm.activeIndex === btnIndex
                                    ? 'primary'
                                    : 'outline-primary',"title":btn.title},on:{"click":function($event){_vm.$emit('setIndex', btnIndex);
                                _vm.$emit('update:activeModel', '');
                                _vm.$emit('update:floor_selected', '');}}},[_c('feather-icon',{attrs:{"icon":_vm.iconType(btn.title),"size":"16"}})],1)}),1)],1),_vm._l((_vm.topBarItems),function(tab,tabIndex){return (_vm.activeIndex === tabIndex)?[_vm._t('left-' + tabIndex)]:_vm._e()})],2)],1),_c('b-col',{attrs:{"cols":"10"}},[_c('vue-perfect-scrollbar',{staticStyle:{"height":"calc(100vh - 215px)"}},[_vm._l((_vm.topBarItems),function(tab,tabIndex){return (_vm.activeIndex === tabIndex)?[_vm._t('right-' + tabIndex)]:_vm._e()})],2)],1)],1),(
            (_vm.topBarItems[_vm.activeIndex].model || _vm.activeModel) &&
            _vm.activeIndex === 0
        )?_c('model-div',{attrs:{"main_model":_vm.topBarItems[_vm.activeIndex].model,"detail_model":_vm.activeModel}}):_vm._e(),_vm._t("background")],2)}
var staticRenderFns = []

export { render, staticRenderFns }