<template>
    <b-list-group class="collapse-menu-link" flush>
        <b-list-group-item
            v-for="(item, index) in list"
            :key="index"
            @click="
                item.equip_id
                    ? $store.commit('jsf35/setEquipSelected', item)
                    : $emit('select', item)
            "
            class="d-flex align-items-center justify-content-between pl-2 py-0 pr-0"
        >
            <div style="min-width: 0">
                <div class="text-truncate">
                    <feather-icon
                        v-if="item.icon"
                        :icon="item.icon"
                        class="mr-50"
                    />
                    <span>{{ item.title }}</span>
                </div>
            </div>

            <b-dropdown right size="sm" no-caret variant="link">
                <template #button-content>
                    <b-button
                        class="btn-icon rounded-circle"
                        size="sm"
                        variant="flat-primary"
                    >
                        <b-icon-three-dots-vertical />
                    </b-button>
                </template>
                <b-dropdown-item
                    v-show="item.detail_model_key"
                    @click="$emit('select', item)"
                >
                    <b-icon-three-dots class="mr-1" />
                    详情
                </b-dropdown-item>
                <b-dropdown-item
                    :disabled="!hasPermission"
                    v-show="item.equip_id"
                    @click="$bvModal.show('model-equip-config')"
                >
                    <b-icon-pencil-square class="mr-1" />
                    编辑
                </b-dropdown-item>
            </b-dropdown>

            <!-- <b-button
                    :disabled="!hasPermission"
                    v-show="item.equip_id"
                    class="btn-icon ml-25"
                    size="sm"
                    :variant="hasPermission ? 'flat-primary' : 'flat-secondary'"
                    @click="$bvModal.show('model-equip-config')"
                >
                    <b-icon-gear-fill />
                </b-button> -->
        </b-list-group-item>
    </b-list-group>
</template>

<script>
import store from "@/store";
import { reactive } from "@vue/composition-api";
import { routerParams } from "@/libs/utils/routerParams";
import { computed, onMounted, ref } from "@vue/composition-api";
export default {
    name: "LeftMenuListGroup",
    props: ["list"],
    setup() {
        const hasPermission = ref(false);
        //判断用户是否有权限
        const checkPermission = () => {
            const permission_type = "subsystems";
            const permission_page = "subsystems/" + routerParams("system_key");
            const permission_action = "edit";
            const permission =
                permission_type +
                "___" +
                permission_page +
                "___" +
                permission_action;
            const userPermission =
                store.state.jsf35.user_permissions ||
                JSON.parse(localStorage.getItem("jsf35UserPermission"));

            let _hasPermission = false;
            if (userPermission && userPermission.length > 0) {
                for (const item of userPermission) {
                    if (item === permission) {
                        _hasPermission = true;
                        break;
                    }
                }
            }
            hasPermission.value = _hasPermission;
            return _hasPermission;
        };
        onMounted(() => {
            checkPermission();
        });

        return {
            hasPermission,
        };
    },
};
</script>

<style lang="scss" scoped>
</style>
